import { FC, ReactNode, useState, useEffect, createContext, useContext } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface VersioningContextType {
  isNewReview: boolean;
  updateIsNewReview: (updatedType: 'old' | 'new') => void;
}

export const VersioningContext = createContext<VersioningContextType>({} as VersioningContextType);

const VersioningProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { newReviewPage } = useFlags();

  const [isNewReview, setIsNewReview] = useState(localStorage.getItem('__review-type') !== 'old');

  const updateIsNewReview = (updatedType: 'old' | 'new') => {
    setIsNewReview(newReviewPage && updatedType !== 'old');
    localStorage.setItem('__review-type', updatedType);
    return;
  };

  useEffect(() => {
    const reviewTypeFromStorage = localStorage.getItem('__review-type') ?? '';
    setIsNewReview(newReviewPage && reviewTypeFromStorage !== 'old');
  }, [newReviewPage]);

  return (
    <VersioningContext.Provider
      value={{
        isNewReview,
        updateIsNewReview
      }}>
      {children}
    </VersioningContext.Provider>
  );
};

export const useVersioning = () => useContext(VersioningContext);

export default VersioningProvider;
